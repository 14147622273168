import * as React from 'react'
import { Box, Container, Typography } from '@material-ui/core'
import { BrowserRouter } from 'react-router-dom'
import withTheme from './theme'
import NavBar from './nav'
import { makeStyles } from '@material-ui/styles'
import ApplicationStatusProvider from './common/applicationProgress/ApplicationProgressProvider'
import { UseSnackbarUI, isLoggedIn } from '@jetkit/react'
import GeneralOptionsProvider from './common/generalOptionsProvider'
import Routes from './routes'
import { useStoreState, useStoreActions } from './store'
import { reloadMe } from './services/userService'
import useRouter from 'use-react-router'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import { StripeProvider } from 'react-stripe-hooks'
import BillingTypeProvider from './common/billingType/BillingTypeProvider'
import { ErrorBoundary } from 'react-error-boundary'
import ReactGA from 'react-ga'
//react-stripe-hooks - git+https://github.com/jetbridge/react-stripe-hooks.git
type StripeT = any

// FIXME: we shouldn't have to declare this - it's already in @types/react-stripe-elements
declare global {
  interface Window {
    Stripe: (publicKey: string, options?: any | undefined) => StripeT
  }
}

ReactGA.initialize(process.env.REACT_APP_TRACKING_ID)

const useStyles = makeStyles({ content: {}, navContainer: { paddingBottom: 80 } })

const App: React.FC = () => {
  const classes = useStyles()
  const stateUser = useStoreState((state) => state.user.current)
  const setCurrentUser = useStoreActions((actions) => actions.user.setCurrent)
  const [stripe, setStripe] = React.useState<StripeT | null>(null)
  const { history } = useRouter()

  // deal with async loading of stripe.js
  // https://www.npmjs.com/package/react-stripe-elements#loading-stripejs-asynchronously
  React.useEffect(() => {
    if (!window || !document) return

    const stripeApiKey = process.env.REACT_APP_STRIPE_KEY_PUBLISHABLE || 'unconfigured'
    if (window.Stripe) {
      setStripe(window.Stripe(stripeApiKey))
    } else {
      const ele = document.querySelector('#stripe-js')
      if (!ele) {
        console.warn('Failed to load stripe.js')
        return
      }

      ele.addEventListener('load', () => {
        // Create Stripe instance once Stripe.js loads
        setStripe(window.Stripe(stripeApiKey))
      })
    }
  }, [])

  React.useEffect(() => {
    ;(async () => {
      // load latest user info from API if we're logged in
      if (!isLoggedIn() || !stateUser || !stateUser.email) return

      // load user and save
      const me = await reloadMe()
      if (!me) return
      setCurrentUser(me)
      // if profile isn't finished and user type is client - send them to signup step two
    })()

    // don't call this again if currentUser changes to avoid infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, setCurrentUser]) //

  function ErrorFallback({ error, resetErrorBoundary }) {
    return (
      <Box display="flex" flexDirection="column" height="100vh" justifyContent="center">
        <Container maxWidth="md">
          <Typography align="center" color="textPrimary" variant="h4">
            Something went wrong:
          </Typography>
          <Typography align="center" color="textPrimary" variant="subtitle2">
            {error.message}
          </Typography>
        </Container>
      </Box>
    )
  }

  return (
    <React.Fragment>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <UseSnackbarUI />
        <StripeProvider stripe={stripe}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <ApplicationStatusProvider>
              <BillingTypeProvider>
                <GeneralOptionsProvider>
                  <BrowserRouter>
                    <div className={classes.navContainer}>
                      <NavBar />
                    </div>
                    <div className={classes.content}>
                      <Routes />
                    </div>
                  </BrowserRouter>
                </GeneralOptionsProvider>
              </BillingTypeProvider>
            </ApplicationStatusProvider>
          </MuiPickersUtilsProvider>
        </StripeProvider>
      </ErrorBoundary>
    </React.Fragment>
  )
}

export default withTheme(App)
