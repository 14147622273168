import * as React from 'react'
import { makeStyles } from '@material-ui/styles'
import { useStoreState } from 'easy-peasy'
import { CircularProgress, Link, Typography } from '@material-ui/core'
import NotificationItem from '../notificationsStrip/notificationItem'
import useRouter from 'use-react-router'
import { Theme } from '../../../../theme'
import EresTooltip from '../../../tooltip/eresTooltip'
import { getNotifications, removeAllNotification } from '../../../../services/notificationsService'
import { useStoreActions } from '../../../../store'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    minHeight: 120,
    overflow: 'scrollY',
    padding: 20,
  },

  empty: {
    fontSize: 14,
    opacity: 0.8,
    color: '#ffffff',
    fontWeight: 200,
  },

  seeAllText: {
    fontSize: 12,
    fontWeight: 500,
    opacity: 0.8,
    color: '#ffffff',
    marginBottom: 30,
    cursor: 'pointer',
  },
  clearAllDiv: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  progress: {
    margin: '0px 10px 0px 0px',
    color: 'fff',
    opacity: 0.5,
  },
  deleteButton: {
    color: '#fff',
    opacity: 0.5,
    whiteSpace: 'nowrap',
    fontSize: 14,
    cursor: 'pointer',
    marginTop: '10px',
  },
}))

interface INotificationsTooltipProps {
  children: React.ReactElement
}

const MAX_VISIBLE_NOTIFICATIONS = 4

const NotificationsTooltip = (props: INotificationsTooltipProps) => {
  const classes = useStyles()
  const [isLoading, setIsLoading] = React.useState(false)
  const notifications = useStoreState((store) => store.notifications.current)
  const { history, location } = useRouter()
  const setCurrentNotifications = useStoreActions((actions) => actions.notifications.setCurrent)

  const navigateToProfile = React.useCallback(() => history.push('/profile'), [history])

  const reloadNotification = async () => {
    await getNotifications()
      .then((notifications) => {
        setCurrentNotifications(notifications)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleRemoveAllNotification = async () => {
    setIsLoading(true)
    await removeAllNotification().then(() => {
      reloadNotification()
    })
  }

  return (
    <EresTooltip
      {...props}
      title={
        <React.Fragment>
          <div className={classes.container}>
            {notifications && notifications.length > 0 ? (
              <>
                <div className={classes.clearAllDiv}>
                  {isLoading ? (
                    <CircularProgress size={20} className={classes.progress} color="inherit" />
                  ) : (
                    <Link
                      underline="none"
                      className={classes.deleteButton}
                      onClick={() => handleRemoveAllNotification()}
                    >
                      Clear All
                    </Link>
                  )}
                </div>
                {notifications.map(
                  (item, index) =>
                    index < MAX_VISIBLE_NOTIFICATIONS && (
                      <NotificationItem
                        key={item.id}
                        variant="light"
                        reloadNotification={reloadNotification}
                        notification={item}
                        iconColor={'#fff'}
                      />
                    )
                )}
              </>
            ) : (
              <Typography className={classes.empty} align="center">
                No messages!
              </Typography>
            )}
          </div>
          {!location.pathname.includes('/profile') && (
            <Typography noWrap align="center" className={classes.seeAllText} onClick={navigateToProfile}>
              View all activity
            </Typography>
          )}
        </React.Fragment>
      }
    />
  )
}

export default NotificationsTooltip
